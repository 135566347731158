.privacy-policy {
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem 1rem 1rem 1rem;
}

.privacy-policy > * {
  max-width: 100%;
}

.privacy-policy > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}