.request-estimate-page {
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 0 0 0 !important;
  background: linear-gradient(to top, #000512, #011f2d);
  -webkit-background: linear-gradient(to top, #000512, #011f2d);
}

.request-estimate-card {
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.submit-button {
  width: 215px;
  background-color: #2fb4b0 !important;
  margin: 2rem !important;
  padding: 0.8rem 1rem 0.8rem 1rem !important;
  text-transform: unset !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.submit-button:hover {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}