.nav {
  grid-area: nav;
  box-shadow: none;
  backdrop-filter: blur(3px);
  border-style: solid;
  border-color: rgba(194, 224, 255, 0.08);
  border-width: 0px 0px thin;
  background: #011f2d !important;
}

.toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 20;
  margin-left: auto;
}

.hamburger, .inline-navigation {
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
}

.hamburger-content-wrapper {
  background: linear-gradient(to top, #000512, #011f2d);
  -webkit-background: linear-gradient(to top, #000512, #011f2d);
  z-index: 10;
  visibility: hidden;
  position: absolute !important;
  top: 0;
  right: -100vw;
  transition: opacity 0.6s ease-out, visibility 0.2s ease-out, right 0.2s ease-out;
  width: 100vw;
  height: 100vh;
}

.hamburger-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5rem !important;
}

.hamburger-content-wrapper.is-visible {
  visibility: visible;
  right: 0;
}

.styled-button {
  width: 215px;
  background-color: #2fb4b0 !important;
  padding: 0.8rem 1rem 0.8rem 1rem !important;
  text-transform: unset !important;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}