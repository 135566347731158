.about {
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  padding: 2rem 0 4rem 0;
  width: 95%;
  /*box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid rgb(215 215 215 / 70%);*/
}

.about-content.row {
  flex-direction: row;
}

.about-content.row-reverse {
  flex-direction: row-reverse; 
}

.about-h1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem;
}