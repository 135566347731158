.footer {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(215 215 215 / 70%);
  /*margin-top: 5rem;*/
  background: linear-gradient(to bottom, #000512, #011f2d);
  -webkit-background: linear-gradient(to bottom, #000512, #011f2d);
}

.quick-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5rem 0 1rem 0;
  width: 100%;
}

.quick-link {
  width: 2rem !important;
}

.footer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0 5rem 0;
  width: 100%;
}

.logo-footer {
  width: 5rem;
  padding: 2rem;
}

.footer-bottom-copy {
  padding: 1rem 0 1rem 1rem;
}