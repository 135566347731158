.app {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: 
  "nav nav nav"
  "main main main"
  "footer footer footer";
  /*background: linear-gradient(to bottom, #e2af68, #b59498);
  -webkit-background: linear-gradient(to bottom, #e2af68, #b59498);*/
  background: #ffffff;
}

#back-to-top-anchor {
  position: absolute;
  top: 0;
  right: 0;
}

.fade-in-section {
  opacity: 0;
  /*transform: translateY(20vh);*/
  visibility: hidden;
  transition: opacity 1s ease-out; /*, transform 1.2s ease-out;*/
  transition-delay: 0.5s;
  will-change: opacity, visibility;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid rgb(215 215 215 / 70%);*/
}

.fade-in-section.is-visible {
  opacity: 1;
  /*transform: none;*/
  visibility: visible;
}

.hero-container {
  position: relative;
  display: flex !important;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hero-content {
  position: absolute;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hero-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.hero-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.hero-image {
  max-width: 500px;
  height: auto;
}

.hero-image > img {
  width: 100%;
}

.hero-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.hero-video-placeholder {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  filter: blur(10px);
  transform: scale(1);
}

.large-logo {
  width: 10rem;
  height: 100%;
  padding: 1rem 0rem 1rem 1rem;
}

.reqEst-logo {
  width: 10rem;
  height: 100%;
  padding: 1rem;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 4rem 0;
  width: 100%;
  /*box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid rgb(215 215 215 / 70%);*/
}

/*.gradient-to-bottom {
  background: linear-gradient(to bottom, #e2af68, #aa8ba3);
  -webkit-background: linear-gradient(to bottom, #e2af68, #aa8ba3);
}

.gradient-to-top {
  background: linear-gradient(to top, #e2af68, #aa8ba3);
  -webkit-background: linear-gradient(to top, #e2af68, #aa8ba3);
}*/

.content.row {
  flex-direction: row;
}

.content.row-reverse {
  flex-direction: row-reverse; 
  background: linear-gradient(to top, #000512, #011f2d);
  -webkit-background: linear-gradient(to top, #000512, #011f2d);
}

.content-desc {
  max-width: 500px;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.about-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.video {
  width: 85%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.video-placeholder {
  position: absolute;
  width: 85%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  object-fit: cover;
  filter: blur(10px);
  transform: scale(1);
}

.link {
  text-decoration: none;
  color: white;
}

.nested-list {
  width: 95%;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  border: 1px solid rgb(215 215 215 / 70%);
}

.skeleton {
  width: 100%;
  height: 25rem !important;
  border-radius: 5px;
}

.skeleton-logo {
  width: 3rem;
  height: 100%;
  margin: 0.5rem !important;
}

.logo {
  width: 4rem;
  height: 100%;
  padding: 0.5rem;
}

.MuiTextField-root > label {
  color: white;
}

.MuiInputLabel-root.Mui-focused {
  color: white !important;
}

.MuiInput-root {
  color: white !important;
}

.MuiInput-root::before {
  /*border-bottom: 1px solid white !important;*/
  border-color: white !important;
}

/*.MuiInput-root::after {
  border-bottom: 1px solid #7f7274 !important;
  border-color: blue !important;
}*/





